<template>
  <div class="ContactPerson">
    <div class="searchNav">
      <!-- <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      > -->
      <el-button class="button1" type="text">
        <span>今日收款:￥ {{ toThousands(entity.DayCollection) }}</span>
        <span style="margin-left:40px">本月收款: ￥ {{ toThousands(entity.MonthCollection) }}</span>
      </el-button>
      <div class="searchParams">
        <!-- 公司组件 -->
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input class="input" v-model="queryParam.CustomerName" placeholder="请输入客户名称进行查询" clearable
          @keydown.enter.native="getDataList"></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%" :max-height="height" v-loading="loading">
        <!-- <el-table-column prop="CompanyName" label="所属公司" width="200">
        </el-table-column> -->
        <el-table-column prop="CustomerName" label="公司名称"> </el-table-column>
        <el-table-column prop="ArrearsMoney" label="欠款余额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.ArrearsMoney) }}
          </template>
        </el-table-column>

        <el-table-column prop="RecentPyments" label="最近收款">
          <template slot-scope="scope">
            {{ toThousands(scope.row.RecentPyments) + ' / ' + scope.row.RecentDate }}
          </template>
        </el-table-column>
        <el-table-column prop="CollectionSum" label="累计收款">
          <template slot-scope="scope">
            {{ toThousands(scope.row.CollectionSum) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="270" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleReceive(scope.row)">收款记录</el-button>
            <el-button type="text" @click="handleSettlmentPay(scope.row)">销账收款</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>
    <!-- <Email ref="Email" />-->
    <!-- 收款记录列表组件 -->
    <CollectMoneyList ref="CollectMoneyList" :parentObj="{ ...this }" />

    <!-- 收款弹窗组件 -->
    <settlementDialogVue ref="settlementDialogVue" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup.vue";
// import ReconArrearList from "./ReconArrearList.vue";
import CollectMoneyList from "./CollectMoneyList.vue"
import settlementDialogVue from './settlementDialog.vue';
import { toThousands } from "../../utils/Tools";
// import Email from "./Email.vue";
export default {
  props: {},
  components: {
    settlementDialogVue,
    CollectMoneyList,
    radioGroup,
    // Email,
  },
  mounted() { },
  created() {

  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {
      // 千分位格式化
      toThousands,
      dialogVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      // 欠款列表数据
      tableData: [],
      fileList: [],
      queryParam: { CustomerName: "" },
      loading: false,
      dialogVisible: false,
      Email: "",
      row: {},
      dialogLoading: false,
      entity: {}
    };
  },
  methods: {


    radioComplete(e) {
      if (e) {
        this.queryParam.CompanyId = e;
        this.getDataList();
        console.log('获取id', this.queryParam.CompanyId)
        // 获取今日收款  本月收款
        this.getHeaderData()

      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.CompanyId = e;
      this.getDataList();
      // 获取今日新欠 全部欠款金额
      this.getHeaderData()
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        return this.$message.error("请选择公司");
      } else {
        this.$http
          .post(
            "/Base/Base_Reconciliation/GetCompanyTemp?CompanyId=" +
            this.queryParam.SupplieId,
            {}
          )
          .then((res) => {
            console.log(res);
            if (res.Success) {
              this.$refs.editForm.openForm(
                "新增对账函",
                res.Data.Content,
                this.queryParam.SupplieId
              );
            } else {
              this.$message.error(res.Msg);
            }
          });
      }
    },
    // 收款记录点击事件
    handleReceive(row) {
      console.log('电机的额收款即可六列表', row, row.CustomerId, this.queryParam.CompanyId)
      this.$refs.CollectMoneyList.openForm(row.CustomerId, row.CompanyId);
    },
    // 销账收款点击事件
    handleSettlmentPay(row) {
      console.log('销账收款点击事件', row, this.queryParam.CompanyId)
      // let obj={
      //   Id:row.Id,
      //   EndTime:row.BillDate.substring(0, 10),
      //   StartTime: "2020-1-1",
      // }
      this.$refs.settlementDialogVue.openForm('收款', JSON.stringify(row));

    },
    // 流水账点击事件
    handleRuningBook(row) {
      console.log('流水账', row, row.Id, this.queryParam.CompanyId)
      let obj = {
        Id: row.Id,
        EndTime: row.BillDate.substring(0, 10),
        StartTime: "",
      }
      this.$refs.CallForPay.openForm('流水账', obj);
    },

    handleView(row) {
      let url = `${location.href.split("#")[0]}#/SendSMSCopy?prop=${row.Id}`;
      window.open(url, "_blank");
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },

    // 获取销账列表列表数据
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/BIlling/GetBillCollectionMoneyList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            this.tableData.forEach(item => {
              item.RecentDate = item.RecentDate ? item.RecentDate.substring(0, 10) : ''
            })
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
    // /获取今日收款 本月收款数据
    getHeaderData() {
      this.$http.post('/BO/BIlling/GetDateCollectionData?CompanyId=' + this.queryParam.CompanyId)
        .then(res => {
          if (!res.Success) return this.$api.msg(res.Msg)
          console.log('获取今日收款 本月收款数据', res.Data)
          this.entity = res.Data
        })
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.del {
  color: red;
}

.button1 {
  color: #d50700;
}

.button1:hover {
  color: #d50700;
}
</style>
