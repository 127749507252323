<template>
  <div class="container">
    <el-dialog title="收款" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" destroy-on-close
    :append-to-body="true" :modal-append-to-body="false">
      <div>
        <h3 style="font-weight:bold;margin-bottom:30px">欠款余额: ￥{{toThousands(entity.ArrearsMoney)}} 元</h3>
      </div>
      <el-radio-group v-model="radio" style="width:100%">
        <div style="display:flex;width:100%;justify-content: space-around;">
          <div class="select" v-for="(item,index) in radioGroupList" :key="item.value">
            <img :src="item.url" alt="">
            <el-radio :disabled="entity.ArrearsMoney == '0'" @change="radioChange" :label="item.key"></el-radio>
          </div>
        </div>
      </el-radio-group>
      <el-input style="color:red" :disabled="entity.ArrearsMoney == '0'" v-model="Money" placeholder="请输入收款金额"></el-input>
      <div v-if="entity.ArrearsMoney == '0'" style="font-size:5px;color:red;padding-top:5px">您的欠款余额为 0, 无法进行收款</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="dialogLoading" type="primary" @click="dialogConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import { getMonthRange, toThousands} from '../../utils/Tools';
export default {
  components: {
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      // 禁止
      disabled:false,
      // 默认选中收款方式   转账
      radio:'转账',
      radioGroupList:[
      {
					key: '转账',
					value: '0',
					url: require('@/assets/zhuanzhang.png'),
				}, {
					key: '微信',
					value: '1',
					url:require('@/assets/weixin.png'),
				}, {
					key: '支付宝',
					value: '2',
					url: require('@/assets/zhifubao.png'),
				}, {
					key: '现金',
					value: '3',
					url: require('@/assets/xianjin.png'),
				}
      ],
      getMonthRange,
      toThousands,
      dialogVisible:false,
      dialogLoading:false,
      entity:{
        PayType:"转账",
        ArrearsMoney:""
      },
      entityRow:{},
      Money:0
    }
  },
  methods: {
    // 收款金额实时输入事件
    inputChange(e){
      console.log('打印e',e, this.entity.ArrearsMoney)

    },
    // 收款方式改变
    radioChange(e){
      console.log('选择的是',e, this.radio)
      switch(e){
        case  '转账':
        this.entity.PayType= 0;
        break;
        case  '微信':
        this.entity.PayType= 1;
        break;
        case  '支付宝':
        this.entity.PayType= 2;
        break;
        case  '现金':
        this.entity.PayType= 3;
        break;
      }
    },

    
     // 点击叉号取消关闭弹窗
     handleClose(done) {
      done();
      // if (!this.Email) return this.$message.error("请输入邮箱");
      // this.dialogLoading = true;
      // this.$http
      //   .post("/Base/Base_Reconciliation/SendMail", {
      //     Id: this.row.Id,
      //     CompanyId: this.row.Company,
      //     RecipientEmail: this.Email,
      //     CcusName: this.row.CustomerName,
      //     CodeDiv: this.row.LetterCode
      //       ? `<div>对账函密码:${this.row.LetterCode}</div>`
      //       : "<div></div>",
      //     Url: "https://hzq.yoojet.com/#/SendSMSMobileCopy?prop=" + this.row.Id,
      //   })
      //   .then((res) => {
      //     this.dialogLoading = false;
      //     if (res.Success) {
      //       this.dialogVisible = false;
      //       this.$message.success("发送成功");
      //     } else {
      //       this.$message.error(res.Msg);
      //     }
      //   });
    },

    // 弹窗确定
    dialogConfirm(){
      console.log('打印确定entity',this.entity,  )
      if(this.entity.ArrearsMoney ==0){
        this.dialogVisible=false
        return
      }
      this.$http.post('/BO/BIlling/AddBIllcollection', {
					"CompanyId": this.entityRow.CompanyId,
					"CompanyName": JSON.parse(localStorage.getItem('CompanyInfoUserInfo')).Title,
					"CustomerId": this.entityRow.CustomerId,
					"CustomerName": this.entityRow.CustomerName,
					"Money": this.Money,
					"Type": 0,
          // 收款方式
					"PayType": this.entity.PayType
				}).then(res => {
					if (!res.Success) return this.$message.error(res.Msg)
					this.$message.success('收款成功')
          // 关闭当前收款弹窗
          this.dialogVisible=false
          // 刷新销账记录列表数据
          this.$parent.tableData =[]
          this.$parent.getDataList()
				})
    },


    openForm(title, row){
      console.log('传递过来传递过来传递过来',title, JSON.parse(row))
      this.entityRow = JSON.parse(row)
      this.entity.ArrearsMoney =  this.entityRow.ArrearsMoney
      this.Money =  this.entityRow.ArrearsMoney
      this.entity.PayType= 0;
      this.dialogVisible=true

      // 获取公司信息存储公司信息 以获取公司名称
      let userInfoId = JSON.parse(sessionStorage.getItem("userInfo")).Id;
      // 获取公司信息
      this.$http
        .post("/Base_Manage/Base_Company/GetDataCompanyData", {
          Search: {
            condition: "CreatorId",
            keyword: userInfoId,
          },
        })
        .then((res) => {
          if (res.Success && res.Data.length > 0) {
            let data = res.Data[0];
            localStorage.setItem("CompanyInfoUserInfo", JSON.stringify(data));
          }
        });
    },

   

  }
}
</script>

<style lang="less" scoped>
  .select{
    justify-content: space-between;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    img{
      margin-bottom: 10px;
    }
  }
/deep/.el-drawer__header {
  margin-bottom: 10px;
}
.qianImg{
  width: 70px;
  height: 160px;	
  transform: rotate(-90deg);
}

.drawerClass {
  padding: 10px;

  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>